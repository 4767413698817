.uploader {
  background-color: #356CA4;
  width: 300px;
  height: 350px;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px 10px;
  border-radius: 10px 0px 0px 0px;
  overflow-y: auto;
}
.uploader__header {
  display: flex;
  justify-content: space-between;
}
.uploader__title {
  font-size: 16px;
  color: #E9E6E6;
}
.uploader__close {
  border: none;
  padding: 3px;
  color: #E9E6E6;
  background: none;
  cursor: pointer;
}
.uploader::-webkit-scrollbar {
  width: 5px;
  background-color: #aaa;
  /* or add it to the track */
}
.uploader::-webkit-scrollbar-thumb {
  background: #07015f;
}
.upload-file {
  background-color: #E9E6E6;
  border-radius: 3px;
  margin: 5px 0;
  padding: 3px 5px;
}
.upload-file__header {
  display: flex;
  justify-content: space-between;
}
.upload-file__name {
  font-size: 0.85em;
}
.upload-file__remove {
  border: none;
  padding: 0px;
  cursor: pointer;
}
.upload-file__progress-bar {
  height: 0.75em;
  border-radius: 3px;
  background-color: #356CA4;
  display: flex;
  margin: 3px 0;
}
.upload-file__upload-bar {
  background-color: green;
  border-radius: 3px;
}
.upload-file__percent {
  color: #E9E6E6;
  left: 10%;
  font-size: 0.7em;
  position: absolute;
}
