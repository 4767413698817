.authorization {
  display: flex;
  flex-direction: column;
  width: 420px;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 15px;
  margin: 10em auto;
}
.authorization__header {
  color: font-color;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0.5em 0 1em;
}
.authorization input {
  margin: 0.75em;
}
.authorization__btn {
  box-shadow: 0 1px 2px 1px #0000;
  background: linear-gradient(180deg, #9ebd13 0%, #6db608 100%);
  border: none;
  padding: 0.75em 4em;
  color: white;
  border-radius: 15px;
  margin-top: 1em;
  transition-duration: 0.1s;
}
.authorization__btn:hover {
  cursor: pointer;
  box-shadow: 0 1px 5px 3px #0005;
  background: linear-gradient(180deg, #9ebd13 0%, #008552 100%);
}
.authorization__error {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: moccasin;
  font-weight: bold;
  font-size: 0.75em;
}
.confirmation {
  display: flex;
  flex-direction: column;
  width: 475px;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  margin: 10em auto;
}
.confirmation__header {
  color: font-color;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0.5em 0 1em;
}
.confirmation__message p {
  text-align: justify;
  margin-bottom: 1em;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #356CA4;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
