.file {
  display: grid;
  grid-template-columns: 1fr 4fr repeat(5, 1fr);
  padding: 4px;
  margin: 5px 0;
  align-items: center;
}
.file:hover {
  transform: scale(1.005);
  cursor: pointer;
}
.file__icon {
  justify-self: center;
  width: 50px;
}
.file__img {
  width: 50%;
}
.file__name {
  grid-column-start: 2;
}
.file__status {
  grid-column-start: 5;
  justify-self: center;
}
.file__status div {
  font-size: 0.75em;
  border-radius: 3px;
  color: whitesmoke;
  padding: 3px 5px;
  justify-self: center;
  text-align: center;
}
.file__status .new {
  background-color: green;
}
.file__status .sent {
  background-color: #ff993a;
}
.file__status .rejected {
  background-color: #ff223f;
}
.file__status .accepted {
  background-color: #000cb4;
}
.file__date {
  grid-column-start: 6;
  justify-self: center;
}
.file__size {
  grid-column-start: 7;
  justify-self: center;
}
.file__btn {
  display: none;
  padding: 3px 5px;
  cursor: pointer;
  font-size: 0.825em;
  height: 2em;
  align-items: center;
}
.file__btn span {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 15px;
  height: 15px;
  margin-right: 2px;
}
.file__delete {
  width: 80px;
  border: 1px solid #A55;
  background-color: rgba(255, 150, 150, 0.15);
  margin: auto;
}
.file__delete span {
  background-image: url('../../../../assets/img/delete_icon.svg');
}
.file__download {
  width: 70%;
  border: 1px solid #356CA4;
  margin: auto;
}
.file__download span {
  background-image: url('../../../../assets/img/download_icon.svg');
}
.file__send {
  width: 100%;
  border: 1px solid #5A5;
  background-color: rgba(130, 255, 130, 0.15);
  margin: auto;
}
.file__send span {
  background-image: url('../../../../assets/img/sendfile.svg');
}
.file:hover .file__status div {
  display: none;
}
.file:hover .file__date {
  grid-column-start: 6;
  display: none;
}
.file:hover .file__size {
  grid-column-start: 5;
  display: none;
}
.file:hover .file__send {
  grid-column-start: 5;
  display: flex;
  align-items: center;
}
.file:hover .file__delete {
  grid-column-start: 6;
  display: flex;
}
.file:hover .file__download {
  grid-column-start: 7;
  display: flex;
}
.file-plate {
  width: 190px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-plate__icon {
  width: 175px;
  text-align: center;
}
.file-plate__name {
  font-size: 0.7em;
}
.file-plate__btns {
  display: none;
}
.file-plate__btns button {
  margin-top: 4px;
  font-size: 0.875em;
  padding: 4px 5px 4px 20px;
  width: 100%;
}
.file-plate:hover .file-plate__btns {
  display: block;
  display: flex;
  flex-direction: column;
}
