.userlist {
  margin: 20px 0;
}
.userlist__header {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr repeat(4, 1fr);
  background-color: antiquewhite;
  padding: 4px;
}
.userlist__name {
  grid-column-start: 2;
}
.userlist__email {
  grid-column-start: 3;
}
.userlist__roles {
  grid-column-start: 4;
}
.userlist__date {
  grid-column-start: 5;
  justify-self: center;
}
.userlist__balance {
  grid-column-start: 6;
  justify-self: center;
}
.userlist__status {
  grid-column-start: 7;
  justify-self: center;
}
.userlist > div:nth-child(2) {
  padding: 2px 3px;
}
.userlist > div:nth-child(2) > div:nth-child(2n) {
  background-color: rgba(148, 148, 148, 0.25);
}
.userlist > div:nth-child(2) > div:nth-child(2n+1) {
  background-color: rgba(148, 148, 148, 0.5);
}
.userlist > div:last-child {
  background-color: #ffffff;
}
.userplate {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
}
.nofiles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.file-enter-active {
  animation: file-enter 500ms forwards;
}
@keyframes file-enter {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
