nav {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #FFFC;
  width: 100%;
  padding: 10px 0;
}
.container {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.navbar {
  display: flex;
  height: 140px;
  padding: 0px;
  margin: 0px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 5px #cccccc;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
}
.navbar__logo {
  padding-top: 5px;
  margin-left: 10px;
  max-width: 240px;
  min-width: 160px;
}
.navbar__logo img {
  width: 100%;
}
.navbar__user {
  display: flex;
  align-items: center;
}
.navbar__header {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
}
.navbar__login {
  margin-left: auto;
  margin-right: 30px;
}
.navbar__login a {
  text-decoration: none;
}
.navbar__login a i {
  background-image: url('../../assets/img/user-login.svg');
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 4px;
  color: #356CA4;
}
.navbar > img {
  margin-left: auto;
}
.navbar__registration {
  margin-right: 25px;
}
.navbar__registration a {
  padding: 10px 15px;
  border-radius: 8px;
  background: linear-gradient(180deg, #356CA4 0%, #0f3cb4 100%);
  text-decoration: none;
  color: #FFF;
  box-shadow: 0 1px 2px 3px #0000;
  transition: all linear 0.1s;
}
.navbar__registration a:hover {
  background: linear-gradient(180deg, #0f3cb4 0%, #356CA4 100%);
  box-shadow: 0 1px 5px 3px #0005;
}
.navbar__userName {
  margin-left: auto;
  margin-right: 20px;
  padding-left: 15px;
}
.navbar__userName a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.navbar__avatar {
  margin-right: 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.navbar__logout__btn {
  margin-right: 20px;
  padding: 4px 12px;
}
.navbar__searchbar {
  margin-left: auto;
}
.navbar__search {
  border: none;
  border-bottom: 1px solid #E9E6E6;
  padding: 3px 5px;
}
.navbar__search:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #356CA4;
}
.navbar__warning {
  background-color: khaki;
  padding: 5px 20px;
  color: brown;
}
.navbar__menu {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
}
.navbar__menu .link {
  padding: 5px 15px 5px 15px;
  border-top: #173b5f 1px solid;
  border-bottom: #173b5f 1px solid;
  background-color: #356ca4;
  border-radius: 7px;
  transition: all 0.35s ease-out;
  cursor: pointer;
  margin-right: 1.25em;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 0.87;
}
.navbar__menu .link:hover {
  border-top: #356ca4 1px solid;
  border-bottom: #356ca4 1px solid;
  background-color: #FFF;
  border-radius: 7px;
}
.navbar__menu .link a {
  text-decoration: none;
  color: #FFF;
}
.navbar__menu .link:hover a {
  text-decoration: none;
  color: #356ca4;
}
.container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
}
