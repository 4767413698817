* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #356CA4;
}
body {
  background: linear-gradient(45deg, #e3ffe7 0%, #d9e7ff 100%);
  background-attachment: fixed;
}
#root {
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh);
}
.wrap {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  /* padding-top: 120px; */
}

/* Pagination */
.page__wrapper {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.page{
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 40%;
  border: solid 2px teal;
  color: teal;
  min-width: 50px;
  text-align: center;
  margin-right: 10px;
  background-color: #e3ffe7;
}

.page__current{
  border: 2px solid salmon;
  font-weight: bold;
  color: salmon;
}