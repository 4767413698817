.myinput {
  border: none;
  width: 90%;
  padding: 0.5em 1em;
  margin-bottom: 1.25em;
  border-radius: 7px;
  border-bottom: 1px solid #356CA4;
  font-size: 16px;
  text-align: center;
}
.myinput:focus {
  outline: none;
  border-bottom: 1px solid #d14444;
  background-color: rgba(255, 248, 220, 0.5);
}
