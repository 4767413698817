.updated_success {
  color: #6db608;
  background-image: url('../../assets/img/success-icon.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  padding-left: 20px;
}
.profile {
  display: flex;
  flex-direction: column;
  width: 470px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 25px 20px;
  margin: 5em auto;
}
.profile__header {
  color: font-color;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0.5em 0 1em;
}
.profile__row {
  display: flex;
  width: 100%;
  margin-bottom: 1.5em;
}
.profile__avatar img {
  border-radius: 15px;
}
.profile__label {
  width: 275px;
  line-height: 2em;
}
.profile__field {
  width: 100%;
}
.profile__avalabel {
  border-bottom: 1px dashed #356CA4;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}
.profile__avalabel:hover {
  border-bottom: 1px dashed #d14444;
  color: #d14444;
  margin-left: 10px;
}
.profile__avaimage {
  display: none;
}
.profile__input {
  padding: 3px 10px;
  width: 100%;
}
.profile__password {
  height: 1em;
  font-size: 2em;
  padding: 0px 10px 4px;
  width: 100%;
}
.profile__savebtn {
  box-shadow: 0 1px 2px 1px #0000;
  background: linear-gradient(180deg, #9ebd13 0%, #6db608 100%);
  border: none;
  padding: 0.875em 2em;
  color: white;
  border-radius: 15px;
  transition-duration: 0.1s;
}
.profile__savebtn:hover {
  cursor: pointer;
  box-shadow: 0 1px 5px 3px #0005;
  background: linear-gradient(180deg, #9ebd13 0%, #008552 100%);
}
.profile > div:last-child {
  margin-bottom: 0px;
}
.center {
  justify-content: center;
  display: flex;
}
.upload__description {
  font-size: 0.85em;
}
.delete-avatar {
  padding: 2px 4px;
  font-size: 0.875em;
}
