.disk {
  margin-top: 20px;
}
.disk__btns {
  display: flex;
  align-items: baseline;
}
.disk__back,
.disk__create {
  padding: 5px 8px;
  background-color: rgba(127, 255, 212, 0.2);
  border-radius: 7px;
  border: 1px solid;
}
.disk__btns button:hover {
  cursor: pointer;
}
.disk__create {
  margin-left: 10px;
}
.disk__upload_input {
  display: none;
}
.disk__upload_label {
  display: inline-block;
  border: 1px solid #356CA4;
  border-radius: 8px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #e0fbfc;
}
.disk__upload_label > div {
  background-image: url('../../assets/img/upload_icon.svg');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: left center;
  width: 18px;
  height: 18px;
  float: left;
}
.disk__search {
  margin-left: auto;
}
.disk__sort {
  margin-left: auto;
  margin-right: 5px;
}
.disk__select {
  margin-left: 5px;
}
.disk__view {
  display: flex;
  align-self: center;
}
.disk__list,
.disk__plate {
  width: 22px;
  height: 22px;
  border: none;
  outline: none;
  cursor: pointer;
  background: no-repeat center center;
  background-size: contain;
  margin: 0 10px;
  padding: 0px;
}
.disk__list {
  background-image: url('../../assets/img/disk-list.svg');
}
.disk__plate {
  background-image: url('../../assets/img/disk-plate.svg');
}
.drop-area {
  width: 100%;
  height: calc(100vh - 50px - 40px);
  margin: 40px;
  font-size: 2em;
  border: dashed #356CA4 2px;
  background-color: rgba(105, 105, 105, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup__content {
  width: 400px;
  background-color: white;
  padding-bottom: 10px;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
}
.popup__content {
  align-items: center;
}
.popup__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5em;
  background-color: antiquewhite;
  padding: 8px 10px;
  border-radius: 10px 10px 0 0;
}
.popup__input {
  border: none;
  width: 90%;
  padding: 0.5em 1em;
  margin-bottom: 1.25em;
  border-radius: 7px;
  border-bottom: 1px solid #356CA4;
  font-size: 16px;
  text-align: center;
}
.popup__input:focus {
  outline: none;
  border-bottom: 1px solid #d14444;
  background-color: rgba(255, 248, 220, 0.5);
}
.popup__title {
  font-size: 1.5em;
}
.popup__create {
  align-self: center;
  padding: 4px 10px;
}
.popup__close {
  font-weight: bold;
  border: none;
  background: none;
  position: relative;
  right: 0px;
  top: 0px;
}
.popup__close:hover {
  cursor: pointer;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #356CA4;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
